export default Object.freeze({
  PRODUCT: "product",
  PC: "ordinateurs",
  PRODUCT_CATALOG: "product_catalog",
  APPLE: "apple",
  SMARTPHONES: "smartPhones",
  ORIDNATEURS: "ordinateurs",
  PRODUCT_TO_SHARE_MARK: "product_to_share_mark",
  PRODUCT_TO_SHARE_FAMILY: "product_to_share_family",
  PRODUCT_TO_BUY_CATEGORIES: "product_to_buy_categories",
  INFORMATIQUE: "informatique",
  ORDINATEURS_TABLETTES: "ordinateursTablettes",
  IMPRIMANTES_SCANNERS: "imprimantesScanners",
  PRODUCT_TO_SHARE_CATEGORIES: "product_to_share_categories",
  PRODUCT_TO_SHARE_SUBCATEGORIES: "product_to_share_subcategories",
  TABLETTE: "tablettes",
  USERS_DETAILS: "users_details",
  PRINTER: "imprimantes", //TODO  A supprimer (a verifeé)
  SCANER: "scanners", //TODO  A supprimer (a verifeé)
  FAVORITES: "favorites",
  MERCARUE: "mercarue",
  USERS_FAVORITES_PRODUCTS: "users_favorites_products",
  USERS_SUBSCRIBERS: "users_subscribers",
  CONSOLE: "videoGames",
  USERS_RATINGS: "users_ratings",
  SCREEN_PC: "screens",
  SOLUTION_STORAGE: "storageSolutions",
  ACCCESOIRES_INFORMATIQUES: "computerAccessories",
  SOLUTION_RESEAUX: "networkSolutions",
  COMPOSANTS_PC: "computerComponents",
  USER_BAG: "users_bag",
  TCHATS: "tchats",
  USERS_NOTIFICATION: "users_notification",
  USERS_CONVERSATIONS: "users_conversations",
  USERS_SEARCH_HISTORY: "users_search_history",
  DISCOUNT: "discount",
  PRODUCT_STATES: "product_states",
  HIGH_TECH: "highTech",
  RECRUITMENTS: "recruitments",
  CONTACTS: "contacts",
  GENERAL_SETTINGS: "general_settings",
  RSS_FEED: "rss_feed", // MercaNews DataBase
  CATEGORIES: "categories", // MercaNews DataBase
  ISODATE: "isoDate", // MercaNews DataBase
  SOURCE_FEED: "source_feed", // MercaNews DataBase
  NOTIFICATIONS: "notifications", // MercaNews DataBase
  SOCIAL_MEDIA: "social_media",
  PAYMENT_SOLUTION: "payment_solution",
  LOGOMERCANEWS: "logo_merca_news",
  USER_ORDER: "user_order",
  USER_PRO_VALIDATION: "user_pro_validation",
  FREQUENTLY_ASKED_QUESTIONS: "frequently_asked_questions",
  GENERAL_STATS: "general_stats",
  PRODUCT_CLASS_NAME: "product_class_name",
});
