<template>
  <div class="invoice-box">
    <v-container>
      <dashboard-core-app-bar />
    </v-container>
    <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
    <table cellpadding="0" cellspacing="0">
      <tr class="top">
        <td colspan="4">
          <table>
            <tr>
              <td class="title">
                <img
                  src="https://mercarue.com/img/logo.242a9996.svg"
                  style="width: 200px"
                />
              </td>

              <td style="text-align: right !important">
                <v-btn
                  :href="facture"
                  color="primary"
                  v-if="facture != null"
                  style="margin-bottom: 40px"
                >
                  <v-icon size="18">mdi-download</v-icon>
                  <h4>imprimer la facture</h4>
                </v-btn>

                <br />
                <span class="prix">
                  Commande # {{ order_id }}<br />
                  Date de Commande: {{ creationDate }}</span
                >
              </td>
            </tr>
          </table>
        </td>
      </tr>

      <tr class="heading">
        <td>Détail Acheteur</td>
        <td>Détail Commande</td>
        <td colspan="2">Détail Vendeur</td>
      </tr>
      <tr>
        <td>Nom d'utilisateur : {{ userName }}</td>
        <td>Etat de la commande : {{ orderState.text }}</td>
        <td>
          Nom et Prénom : {{ nameSeller.lastName }} {{ nameSeller.firstName }}
        </td>
      </tr>
      <tr>
        <td>Nom et Prénom : {{ nameBuy.firstName }} {{ nameBuy.lastName }}</td>
        <td>Etat de livraison : {{ deliveryState.text }}</td>
        <td>
          Addresse :
          {{ addressSeller.address }} {{ addressSeller.complement }}
          {{ addressSeller.postalCode }}
          {{ addressSeller.villeName }}
        </td>
      </tr>
      <tr>
        <td>
          Addresse : {{ addressBuy.address }} {{ addressBuy.complement }}
          {{ addressBuy.postalCode }}
          {{ addressBuy.villeName }}
        </td>
        <td>Mode de paiement : {{ paymentMode.text }}</td>
        <td v-if="numTelSeller != null">
          Numero de téléphone : {{ numTelSeller.numeroTelephone }}
        </td>
      </tr>
      <tr>
        <td>Numero de téléphone : {{ numTelBuy.numeroTelephone }}</td>
        <td>Etat du paiement : {{ paymentState.text }}</td>
        <td></td>
      </tr>
    </table>
    <v-card>
      <v-card-title>Liste des Produits</v-card-title>
      <v-data-table :headers="headers" :items="product" class="elevation-1">
        <template v-slot:item.productName="{ item }">
          {{ item.productName }}
        </template>

        <template v-slot:item.mark="{ item }">
          {{ item.mark }}
        </template>
        <template v-slot:item.productPrice="{ item }">
          {{ item.productPrice }} DT
        </template>
        <template v-slot:item.productQteInBag="{ item }">
          {{ item.productQteInBag }}
        </template>
      </v-data-table>
    </v-card>
    <table style="margin-top: 100px">
      <tr>
        <td>
          <h4>Merci pour votre confiance</h4>
        </td>
      </tr>
      <tr>
        <td style="text-align: right !important">
          <span class="prix">Prix HT :</span>
          {{ priceHt }} DT
        </td>
      </tr>
      <tr>
        <td style="text-align: right !important">
          <span class="prix"> Taxe : </span> {{ taxe }}%
        </td>
      </tr>
      <v-divider />
      <tr>
        <td style="text-align: right !important">
          <span class="prix"> Prix TTC : </span>{{ priceTtc }}
          DT
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import db from "./../../../firebaseInit";
import NodesName from "./../../utils/nodesName";
import "vue-json-pretty/lib/styles.css";
import moment from "moment";
import FunctionsUtils from "../../utils/functions";
export default {
  name: "OrdersDetail",
  components: {
    DashboardCoreAppBar: () =>
      import("../../materialDashboard/components/core/AppBar.vue"),
  },

  data() {
    return {
      items: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Liste des commandes",
          disabled: false,
          to: "/Mercarue/orders/Orders",
        },
      ],
      order_id: null,
      creationDate: null,
      deliveryAddress: [],
      userId: null,
      client: null,
      userName: null,
      orderState: [],
      deliveryState: [],
      paymentMode: [],
      paymentState: [],
      productName: null,
      facture: null,
      productOrderList: [],
      product: [],
      total: 0,
      nameSeller: [],
      nameBuy: [],
      addressSeller: [],
      addressBuy: [],
      numTelBuy: [],
      numTelSeller: [],
      taxe: null,
      priceHt: null,
      priceTtc: null,
    };
  },

  beforeRouteEnter(to, from, next) {
    localStorage.setItem("ProductId", to.params.doc_id);
    db.collection(NodesName.USER_ORDER)
      .doc(to.params.doc_id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          next((vm) => {
            vm.order_id = doc.data().orderIdentifier;
            vm.creationDate = moment(
              new Date(doc.data().creationDate.seconds * 1000)
            ).format("DD/MM/YYYY");
            vm.userId = doc.data().userId;
            vm.userName = doc.data().clientUsername;
            vm.addressBuy = doc.data().deliveryAddress;
            vm.addressSeller = doc.data().sellerAddress;
            vm.orderState = FunctionsUtils.getPaymentState(
              doc.data().orderState
            );
            vm.deliveryState = FunctionsUtils.getPaymentState(
              doc.data().deliveryState
            );
            vm.paymentMode = FunctionsUtils.getPaymentState(
              doc.data().paymentMode
            );
            vm.paymentState = FunctionsUtils.getPaymentState(
              doc.data().paymentState
            );

            vm.product = doc.data().productOrderList;
            vm.nameBuy = doc.data().deliveryAddress;
            vm.nameSeller = doc.data().sellerAddress;
            vm.facture = doc.data().invoice;
            vm.numTelBuy = doc.data().deliveryAddress;
            vm.numTelSeller = doc.data().sellerAddress;
            vm.taxe = doc.data().tax;
            vm.priceHt = doc.data().price_HT;
            vm.priceTtc = doc.data().price_TTC;
          });
        } else {
          console.log("No such document!");
        }
      });
  },
  computed: {
    headers() {
      return [
        { text: "Produit", value: "productName" },
        { text: "Marque", value: "productMark" },
        { text: "Prix", value: "productPrice" },
        { text: "Quantité", value: "productQteInBag" },
      ];
    },
  },

  methods: {
    redirectToClient() {
      this.$router.push({
        name: "UserDetail",
        params: {
          userId: this.userId,
          username: this.username,
        },
      });
    },
  },

  created() {},
};
</script>

<style>
.invoice-box {
  max-width: auto;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
.prix {
  font-weight: bold;
}
</style>
